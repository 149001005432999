import { BalanceSheetsEmailParts } from '../dto';

const getDefaults = (): BalanceSheetsEmailParts => ({
  content: '',
  signature: '',
  subContent: '',
});

export const getBalanceSheetsEmailPartsMock = (p?: Partial<BalanceSheetsEmailParts>): BalanceSheetsEmailParts => ({
  ...getDefaults(),
  ...p,
});
