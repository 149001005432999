import { DemoMode } from '../dto';

const getDefaults = (): DemoMode => ({
  isActive: false,
});

export const getDemoModeMock = (p?: Partial<DemoMode>): DemoMode => ({
  ...getDefaults(),
  ...p,
});
