import { PaymentMethod } from '@dougs/subscription/dto';
import { CompanyLegalForm } from '../enums';

export interface CompanyMetadata {
  socialSuspendedAt: Date;
  socialCompletedAt: Date;
  dateOfLegalPublication?: Date;
  legalPublicationNewspaper?: string;
  isDomiciliated?: boolean;
  isAmbulant?: boolean;
  isTns: boolean;
  skipPayrollSurvey: boolean;
  automaticPayroll: boolean;
  social: CompanyMetadataSocial;
  courtsAdministrationService?: CompanyCourtAdministration;
  companyTaxesService?: CompanyTaxesService;
  hasCertifiedOrganism?: boolean;
  certifiedOrganism?: CertifiedOrganism;
  providentOrganismRate: string;
  providentOrganismExecutiveRate: string;
  hasPayrollsInLastTwelveMonths: boolean;
  pipedriveId?: number;
  partnershipAccountingTrialPeriodDays?: number;
  partnershipAccountingFreeMonths?: number;
  partnershipAlreadyPaidMonthCount?: boolean;
  partnershipAccountingHasRemainder?: boolean;
  partnershipDiscountOnCompanyCreation?: number;
  partnershipSubscriptionPaymentMethod?: string;
  creation?: CompanyMetadataCreation;
  inpiActivity?: { code0: string | null; code1?: string | null; code2?: string | null; code3?: string | null };
  paymentMethods?: PaymentMethod[];
  legalRepresentativeEmail: string;
  legalRepresentativeFirstName: string;
  legalRepresentativeLastName: string;
  prefilled?: boolean;
  resiliationReason?: string;
  vatROF?: string;
  isROF?: string;
  country?: string;
  hasHighLabftRisk?: boolean;
  bankNameOfFundsSubmission?: string;
  proofOfAddressInfo?: CompanyProofOfAddressInfo;
}

export interface CompanyCourtAdministration {
  street: string;
  city: string;
  zipcode: string;
  phone: string;
  webSite: string;
  name?: string;
}

export interface CompanyMetadataSocial {
  isDomiciliated?: boolean;
  hasCertifiedOrganism?: boolean;
  retirementOrganism: string;
  providentOrganism: string;
  providentOrganismRate: number;
  providentOrganismExecutiveRate: number;
  cooperative: string;
  cooperativeRate: string | number;
  customCooperativeRate: number;
  corporatePurposeActivityType: string;
  corporatePurposeId: number;
  corporatePurposeModifiedAt: Date | null;
}

export interface CertifiedOrganism {
  code: string;
  subscriptionNumber?: string;
  name?: string;
  street?: string;
  zipcode?: string;
  city?: string;
  agreementNumber?: number;
  subscriptionDate?: Date;
}

export interface CompanyMetadataCreation {
  when: 'later' | 'today' | 'nextWeek' | 'nextMonth' | null;
  legalForm: CompanyLegalForm;
  hasMultipleAssociates: boolean;
  showAccreAndSpouse?: boolean;
  resiliationReason?: string;
  paymentMethods?: PaymentMethod[];
}

export interface CompanyTaxesService {
  name: string;
  street: string;
  city: string;
  zipcode: string;
  phone: string;
  webSite: string;
  normalizedName: string;
}
export interface CompanyProofOfAddressInfo {
  type: CompanyTypeOfAddressProof;
  expirationDate: Date | null;
}
export enum CompanyTypeOfAddressProof {
  PROPERTY_TAX = 'PROPERTY_TAX',
  OTHER = 'OTHER',
}
