export enum CompanyLegalForm {
  ASS = 'ass',
  EI = 'ei',
  EIRL = 'eirl',
  EURL = 'eurl',
  GIE = 'gie',
  SARL = 'sarl',
  SARL_FAMILLE = 'sarl famille',
  SAS = 'sas',
  SASU = 'sasu',
  SC = 'sc',
  SCI = 'sci',
  SELARL = 'selarl',
  SELARLU = 'selarlu',
  SELAS = 'selas',
  SELASU = 'selasu',
  SNC = 'snc',
}
