import { AccountingYear } from '../dto';

const getDefaults = (): AccountingYear => ({
  isCurrent: true,
  openingDate: new Date('2022-03-03'),
  accountingIndex: 1,
  journal: null,
  accountingYearBalanceSheets: [],
  companyId: 1,
  closed: false,
  previousAccountantFec: null,
  liquidationDate: new Date('2022-03-03'),
  id: 1,
  finalPrints: [],
  isLate: false,
  finalPrintZips: [],
  previousAccountantDocuments: [],
  accountingYearTaxReportEdis: [],
  companyTaxes: [],
  fecExports: [],
  generalAssemblies: [],
  mostRecentSignedBalanceSheet: [],
  refundCompanyTaxes: [],
  metadata: {
    accountingReportOperationId: undefined,
    nonNullBalancesAfterReversals: undefined,
    reversalsGeneratedAt: new Date('2022-03-03'),
    lateSurveyPenalty: {
      isDisabled: false,
      userId: 1,
      date: undefined,
      reason: undefined,
    },
    previousAccountant: {
      isMan: false,
      email: '',
      city: '',
      firm: '',
      firstName: '',
      lastName: '',
      phone: '',
      street: '',
      zipcode: '',
    },
    disableTnsProvisionWorkflow: false,
    isAccountingMadeByOwner: false,
    skipDougsTaxIncomeComputation: false,
    isIntermediateClosing: false,
  },
  isFirst: true,
  closingDate: new Date('2021-11-10'),
  effectiveClosingDate: new Date('2021-11-10'),
  isCompanyFirstAccountingYear: true,
  survey: {
    completionPercent: 1,
    completed: true,
  },
  closingTaskId: 1,
  accountingType: 'cash',
});

export const getAccountingYearMock = (p?: Partial<AccountingYear>): AccountingYear => ({
  ...getDefaults(),
  ...p,
});
